import { Button, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import './style.less'
import { DownloadOutlined } from '@ant-design/icons'
import { httpGetRevisor, httpPostRevisor } from '../../services/http'
import FileSaver from 'file-saver'
import { storeAuthTokenRevisor, getStoredAuthTokenRevisor } from '../../utils/auth-token'
import { ITaxYear } from '../ComplianceReports/types'
import { format } from 'date-fns'

const placeholderData: Partial<ITaxYear> = {
    ll_user_full_name: '--- ---',
    tax_year: 0,
    report_ids: [],
}

export default function RevisorLandingPage({ reportToken }) {
    const [taxYear, setTaxYear] = useState<Partial<ITaxYear>>(placeholderData)
    const [isDownloading, setIsDownloading] = useState<boolean>(false)

    async function downloadTaxYear() {
        setIsDownloading(true)
        httpGetRevisor(
            `/landing/status?path_name=download-tax-year-from-token-${reportToken}`
        )
        taxYear.report_ids.forEach((tyd) => {
            if (!tyd?.report_url) {
                setIsDownloading(false)
                message.error('Could not download tax year!')
                return
            }
            FileSaver.saveAs(
                tyd?.report_url,
                `compliacne-report-${format(new Date(tyd.date_start), 'yyyy-MM-dd')}-${format(
                    new Date(tyd.date_end),
                    'yyyy-MM-dd'
                )}.zip`
            )
        })
        setIsDownloading(false)
    }

    useEffect(() => {
        if (!reportToken) {
            return
        }
        const token = reportToken
        setIsDownloading(true)
        httpPostRevisor<{ tax_year_data: ITaxYear; authentication: { access_token: string } }>(
            `/revisor-reports/token/${token}`
        )
            .then((res) => {
                if (!res?.data?.tax_year_data) {
                    setIsDownloading(false)
                    return
                }
                setTaxYear(res?.data.tax_year_data)
                const oldAuthToken = getStoredAuthTokenRevisor()
                if (!oldAuthToken && res?.data?.authentication?.access_token) {
                    storeAuthTokenRevisor(res?.data?.authentication?.access_token)
                }
                setIsDownloading(false)
            })
            .catch((ex) => {
                console.log(ex)
            })
    }, [reportToken])

    return (
        <>
            <div className="revisor-background">
                <div className="revisor-main-container">
                    <div className="revisor-top-bar">
                        <div className="revisor-header-content">
                            <Link to="/">
                                <div style={{ marginRight: 20 }} className="logoImg" />
                            </Link>
                            <h3 className="revisor-header-title">ll33a.dk - Compliance Report</h3>
                        </div>
                    </div>
                    <div className="settings-container">
                        <div className="revisor-title">
                            <h3>
                                Tilgå {taxYear.ll_user_full_name}'s rapport for skatteåret{' '}
                                {taxYear.tax_year.toString()}.
                            </h3>
                        </div>
                        {/* <Button
                            onClick={viewReportInBrowser}
                            className="settings-button"
                            type="primary"
                            icon={<FileDoneOutlined />}
                        >
                            Se i Browser
                        </Button> */}
                        <div style={{ width: '100%' }}>
                            Vær opmærksom på at filen kan være meget stor.
                            <Button
                                onClick={downloadTaxYear}
                                loading={isDownloading}
                                className="revisor-btn"
                                icon={<DownloadOutlined />}
                            >
                                Download rapport
                            </Button>
                        </div>
                        <div className="revisor-title">
                            <h4>
                                Rapporten indeholder {taxYear.report_ids.length.toString()} .zip-fil
                                {taxYear.report_ids.length > 1 && 'er'} der{' '}
                                {taxYear.report_ids.length > 1 && 'hver '}repræsenterer et
                                udlandsophold.
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
